


@import "application/views/scripts/scss/studyladder-theme";


@media (min-width: 1600px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
	    max-width: 1500px !important;padding-left:9rem!important;padding-right:9rem!important;
	}

}
@media (min-width: 1400px) {
	.mb-xxl-5, .my-xxl-5 {
	    margin-bottom: 3rem!important;
	}
}



nav.navbar .form-inline .form-control-sm {
    height: 22px;
    margin-bottom: 1px;
    margin-top: 1px;
}



body {
	color:#424242;
	//background-color: #eee;
}
.btn {

    padding: .475rem 1.25rem;
}
h1 {
	font-size:60px !important;font-weight: 700;margin-bottom: 1rem;
}
h2 {
	font-size:48px !important;font-weight: 700;
}
h3 {
	font-size:34px !important;font-weight: 700;padding-bottom:1.5rem;
}
h4 {
	font-size:24px !important;font-weight: 700;padding-bottom:1.5rem;line-height:32px;
}
p, li{
	font-size:17px;
}
p.medium{
	font-size:18px;
}
p.large{
	font-size:20px;
}
.seperate-list-1 li{
	padding-bottom:8px;margin-left:-20px;
}
.hr-holder{
	margin-left: auto;margin-right: auto;width: 50%;background-color: #fff;left: 0;right: 0;position: absolute;
}
.home-hr {
	margin-top:0; margin-bottom:0; background-color:#85909a; height:1px; border-top:0px;
}
.mt-6 {
    margin-top: 5rem!important;
}
.py-6 {
	padding-top: 5rem!important;
	padding-bottom: 5rem!important;
}

nav.navbar {
	padding:0;
}

.navbar .container {
	  background-color: #fff;padding-left:2rem; padding-right:2rem;
}

main .container {
	    background-color: #fff;
}

.bright-pink { color: #ff1675; }
.bright-orange { color:#ff7800;}
.bright-green{ color:#08b419;}
.bright-teal{color:#0ac0b5;}
   
.pale-green{color:#dffcdf;}

.activity-summary .lead {
	line-height: 1.5 !important;
}

.user-segments .card-body {
	padding-left: 0px; padding-right: 0px; padding-bottom: 0.5em;
	&>p {
		margin-left:0.5em; margin-right:0.5em; max-width:100%; margin-top: 1em;
	}
}


.text-button {
	font-size: 1.5rem;font-weight: 700;
}
.border-1 {
	border:1px solid #333;
}
div.section {padding-top: 4em;padding-bottom: 4em;}	


.split-box {
	min-height: 520px;
}

@media (min-width: 996px){
	.py-lg-6 {
		padding-top: 6rem!important;
		padding-bottom: 6rem!important;
	}
}



@media (max-width: 1600px) {

	main .container {
	    padding-left: 4rem;
	    padding-right: 4rem;
	}	
}



@media (min-width: 1400px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
	    max-width: 1240px;
	}
}
@media (min-width: 1600px){
	.container, .container-lg, .container-md, .container-sm, .container-xl {
	    max-width: 1500px;
	}
}
@media (max-width: 1600px) {
	


	div.section {padding-top: 3em;padding-bottom: 2em;}	
	main .container {background-color: #fff;padding-left: 3rem;padding-right: 3rem;}

}
@media (max-width: 1400px) {
	
	h1 {font-size:54px !important;}
	h2 {font-size:36px !important;}
	h3 {font-size:26px !important;padding-bottom:1.5rem;}
	h4 {font-size:20px !important;padding-bottom:1.5rem;line-height:32px;}
	div.section {padding-top: 3em;padding-bottom: 2em;}	
	main .container {background-color: #fff;padding-left: 2rem;padding-right: 2rem;}

}
@media (max-width: 1200px) {
	
	h1 {font-size:40px !important;}
	h2 {font-size:32px !important;}
	h3 {font-size:22px !important;padding-bottom:0.8rem;}
	h4 {font-size:18px !important;padding-bottom:0.8rem;line-height:32px;}
	p, li{font-size:17px;}		
	
	h3 {
		padding-bottom:1.2rem;
	}
	p, li{font-size:16px;}	
}
@media (max-width: 996px) {
	.split-box {
		min-height: auto;
	}

}

@media (max-width: 767px) {
	
	h1 {
		font-size:34px !important;
	}
	h2 {
		font-size:28px !important;    line-height: 1.2em;
	}
	h3 {
		font-size:22px !important;padding-bottom:0.6rem;
	}
	h4 {
		font-size:16px !important;padding-bottom:1.0rem;line-height:24px;
	}
	p, li{
		font-size:15px;
	}	
	div.section {padding-top: 2em;padding-bottom: 2em;}	
	main .container {background-color: #fff;padding-left: 1rem;padding-right: 1rem;}

}

